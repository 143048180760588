import React, { useContext, useMemo } from "react";
import "./index.css"
import background from "../../assets/images/background.png";
import logo from "../../assets/images/logo.png";
import { QuestionarioContext } from "../../context/QuestionarioContext/QuestionarioContext";
export function Header() {
    const {empreendimento, fotosEmpreendimento} = useContext(QuestionarioContext);
    
    const fotoBackground = useMemo(()=>{
        return fotosEmpreendimento.find((foto)=>{
            return foto.background === 1;
        })
    },[fotosEmpreendimento]);

    const fotoPrincipal = useMemo(()=>{
        return fotosEmpreendimento.find((foto)=>{
            return foto.principal === 1;
        })
    },[fotosEmpreendimento]);



    return (
        <div className="header-section"
            style={{
                backgroundImage: `url(${fotoBackground?.endereco})`
            }}
        >
            <div className="empreendimento-section">
                <img className="logo-empreendimento" src={fotoPrincipal?.endereco} alt="logo_empreendimento"/>
                <span className="nome-empreendimento">{empreendimento.nome}</span>
            </div>
        </div>
    )
}